@import "styles/style.module.scss";

/** This class will get added for new design */
.footerContainer {
  background: $grey10;
  position: relative;

  .footerContent {
    background: $grey10;
    padding: 80px 0px;

    @include for-size(phone) {
      padding: 32px 0px 100px;
    }
  }

  .footerLogo {
    @include flex(center, flex-end, row);
    align-self: flex-end;
    margin-top: auto;

    @include for-size(phone) {
      align-self: center;
    }
  }

  .accordionContainer {
    @include flex(stretch, space-between, row);
    flex-wrap: wrap;
    padding-bottom: 56px;
    border-bottom: 3px solid #e7e7e5;
    margin-bottom: 56px;

    @include for-size(phone) {
      flex-direction: row;
    }
  }

  .accordion {
    flex: 1 0 auto;
    @include flex(flex-start, flex-start, column);

    .accordionHeader {
      @include flex(center, flex-start, row);
      height: 20px;
    }

    .accordionBody {
      padding: 12px 0px 0px;
    }

    @include for-size(phone) {
      width: auto;
      flex: 0 1 50%;
      border-color: $white;
      border: none;

      .accordionHeader {
        height: 20px;

        .expandIcon {
          display: none;
        }
      }
    }
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $white;

    @include for-size(phone) {
      padding: 0;
      min-width: unset;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .footerLinks {
    @include flex(flex-start, center, column);
    gap: 12px;

    li {
      list-style-type: none;
      padding: 0;

      &::before {
        width: 0;
        height: 0;
      }

      a {
        font-size: 12px;
        line-height: 14px;
        font-family: $fontNew;
        @include for-size(phone) {
          color: $grey1;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    @include for-size(phone) {
      padding: 0;
    }
  }

  &.super6Footer {
    background: $grey1;

    .footerContent {
      background: $grey1;
    }

    .title {
      color: $grey10;
    }

    .footerLinks {
      li {
        a {
          color: $grey9;
        }
      }
    }
  }
}

.xl {
  max-width: 1276px;
}

.full {
  max-width: 100%;
}

.footerContent {
  padding: 30px;
  background: #020d11;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 767px) {
    padding: 30px 30px 80px 30px;
  }
}

.footerLogo {
  @extend .textCenter;

  @media only screen and (max-width: 767px) {
    order: 2;
  }
}

.accordionContainer {
  padding: 0 0 20px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.accordion {
  border-bottom: 1px solid transparent;

  .accordionHeader {
    height: 30px;

    @media only screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }
  }

  .accordionBody {
    padding: 10px 0;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    border-color: rgba(35, 43, 46, 0.86);
    padding: 10px 0;

    &:last-child {
      border: none;
    }
  }
}

.footerLinks {
  @extend .flex;
  justify-content: unset;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    position: relative;
    padding: 0 15px;

    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $white;

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    a {
      color: $white;
      line-height: 27px;

      @media only screen and (max-width: 767px) {
        color: #fcb716;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 0 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  font-size: 14px;
  line-height: 24px;
  font-family: $fontNew;
  margin: 0;
  color: #fcb716;

  @media only screen and (max-width: 767px) {
    min-width: 200px;
    min-height: 24px;
    text-transform: uppercase;
    color: $white;
    padding: 0 5px;
  }
}

.expandIcon {
  @media only screen and (max-width: 767px) {
    width: 7px;
    height: 7px;
    border-bottom: 1px solid $white;
    position: relative;
    display: inline-block;
    margin: 0 0 4px;

    &:before {
      content: "";
      position: absolute;
      height: 7px;
      border-left: 1px solid $white;
      top: 3px;
      bottom: 0;
      left: 3px;
    }
  }

  &.active {
    &::before {
      height: 0;
      top: 0;
    }
  }
}

.footerLinksWrap {
  display: flex;

  @include for-size("desktop") {
    justify-content: space-between;
    align-items: center;
  }

  @include for-size("phone") {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.footerHeading {
  color: #121414;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
}

.downloadAppContainer,
.socialLinksWrap {
  text-align: center;
}

.appLinks,
.socialLinks {
  display: flex;

  @include for-size("desktop") {
    gap: 12px;
  }

  @include for-size("phone") {
    gap: 30px;
  }
}

.appLinks {
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 45px;
  }
}
.socialLinks {
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 40px;
  }
  [class*="icon-twitter"] {
    color: $black;
  }
}
.logo {
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 68px;
  }
}
