#next {
  height: 100%;
}

.container {
  max-width: 1064px;
  margin: auto;
  height: 100%;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.containerNew {
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;
  @media only screen and (min-width: 1276px) {
    padding: 0;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding-bottom: 80px;
  }
}

.textCenter {
  text-align: center;
}

.textUppercase {
  text-transform: uppercase;
}
.textCapitalize {
  text-transform: capitalize;
}
.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lineClamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // word-break: break-word;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsEnd {
  align-items: flex-end;
}

.justifyContentStart {
  justify-content: flex-start;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.justifyContentCenter {
  justify-content: center;
}

.textPrimary {
  color: $primaryColor;
}

.textSecondary {
  color: $secondaryColor;
}

.scroll {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}

.hideVisibility {
  visibility: hidden;
}

.displayNone {
  display: none;
}

.customScrollSlider {
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 -20px;
  padding: 0 20px;
  &::-webkit-scrollbar {
    display: none;
  }
}
