@import "styles/sass/partials/variables";
@import "styles/sass/partials/mixins";

.root {
  font-size: 13px;
  padding: 9px 13px 9px 13px;
  @include border-radius(5px);
  @include box-shadow(0 2px 4px 0 rgba($black, 0.2));
  text-transform: uppercase;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.primary {
  background-color: $orange10 !important;
  color: white !important;
  &:hover {
    background-color: $orange10 !important;
    color: white !important;
  }
}
.secondary {
  background-color: white !important;
  color: $secondaryColor !important;
}
.sizeSmall {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  text-transform: none;
}
