@import "styles/style.module.scss";

.contentSection {
  background: $white;
  padding-top: 102px;
  font-family: $fontNew;
  color: $grey10;
  @include for-size(phone) {
    padding-top: 120px;
    width: 100%;
  }
  &.hideSearch {
    @include for-size(phone) {
      padding-top: 56px;
    }
  }
  &.appContainer {
    padding-top: 0;
  }

  &.hideNewHeader {
    padding-top: 0;
  }
}
