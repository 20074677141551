// Grey Color
$grey1: #f6f6f6;
$grey2: #f1f1ef;
$grey3: #e7e7e5;
$grey4: #d9d9d7;
$grey5: #ccccc9;
$grey6: #bebebb;
$grey7: #989795;
$grey8: #71716e;
$grey9: #4b4a48;
$grey10: #121414;
$grey17: #888888;
$grey18: #a3b5bb;
$grey19: #a4a4a4;
$lightgrey2: #f3f3f3;
$lightgrey1: #fcfcfc;
$lightgrey3: #d8d8d8;
$lightgrey4: #d7d7d7;

//// green shade
$green1: #e9faee;
$green2: #d5efdc;
$green3: #acdab9;
$green4: #98cfa8;
$green5: #6fb984;
$green6: #1e8e3e;
$green7: #187232;
$green8: #125525;
$green9: #0c3919;
$green10: #092b13;
$green10: #144746;
$green11: #e3f0ec;
$green12: #1c8665;
$green15: #4aa54a;

$lightGreen1: #d2f8e5;
$darkGreen1: #198754;

//banner color
$banner1: #e5f6fe;

// Consult Color or Blue
$brandColor1: #ddf1f8;
$brandColor2: #c4dde5;
$brandColor3: #97c1ce;
$brandColor4: #6aa4b7;
$brandColor5: #3d88a0;
$brandColor6: #106c89;
$brandColor7: #00222d;
$brandColor8: #0d5b74;
$brandColor9: #014459;
$brandColor10: #013343;
$brandColor11: #007c93;
$brandColor12: #00889e;

// Red Color Darkest to Lightest
$errorColor: #d0483d;
$red1: #fcf2f1;
$red2: #f4c9c5;
$red3: #eeaaa4;
$red4: #e3756b;
$red5: #ba4037;
$red6: #a53930;
$red7: #8f312a;
$red8: #890000;
$red9: #5a1f1a;
$red10: #3c1410;
$red12: #e02020;
$red13: #e02828;
$red14: #a30808;

// Orange Color Darkest to Lightest
$orange: #eb762e;
$orange0: #fcb716;
$orange1: #fcf0e4;
$orange2: #f6c79f;
$orange3: #f19e5a;
$orange4: #ef8a3f;
$orange5: #d96d2a;
$orange6: #b55b22;
$orange7: #904819;
$orange8: #6d3611;
$orange9: #482408;
$orange15: #e87e38;
$orange16: #fca532;

// Yellow/ Warning Color
$yellow1: #fff3d6;
$yellow2: #fde4b1;
$yellow3: #fad382;
$yellow4: #f8c152;
$yellow5: #e39f17;
$yellow6: #d18f0a;
$yellow7: #976707;
$yellow8: #5c3f04;
$yellow9: #3c1410;

// Purple / Info Color Darkest to Lightest
$purple1: #f1edfd;
$purple2: #d5c7f9;
$purple3: #b9a1f4;
$purple4: #9d7bf0;
$purple5: #8155eb;
$purple6: #5535a1;
$purple7: #3f267c;
$purple8: #291657;
$purple9: #130632;

// Pink Color Darkest to Lightest
$pink: #410114;
$pink1: #fcedf2;
$pink2: #f7c8d7;
$pink3: #f2a4bc;
$pink4: #ec7fa0;
$pink5: #e75a85;
$pink6: #c6486e;
$pink7: #a53658;
$pink8: #832541;
$pink9: #62132b;

// Brown Color
// $espresso: #845325;
// $chestnut: #aa7038;
// $almond: #be8049;
// $golden: #c5934e;
// $honey: #cf9653;
// $natural: #daa977;
// $warmbeige: #deb27e;
// $warmIvory: #f4c391;
// $ivory: #eeceaa;
// $pocelain: #f5d3b9;

// Generic Colors
$black: #000000;
$white: #ffffff;
$white2: #f0f1ec;
$white3: #dcdfce;
$white4: #f7f8f5;

// card Color
$cardGreen2: #eefbf5;
$cardPurple2: #f3f0ff;

//Semantic Colors
// $successColor:#1C8665 ;
$infoColor: #6b45c6;
$warningColor: #f5af23;

// pharma color
$pharma1: #e5f9f8;
$pharma2: #bee3e1;
$pharma3: #abd8d6;
$pharma4: #52aba9;
$pharma5: #228a87;
$pharma6: #165d59;
$pharma7: #1a504c;
$pharma8: #12403e;
$pharma9: #0e2a2a;
$pharma10: #0c1f1f;
$pharma11: #658f9b;

//Skin tone
$skinColor1: #f5d3b9;
$skinColor2: #eeceaa;
$skinColor3: #f4c391;
$skinColor4: #deb27e;
$skinColor5: #daa977;
$skinColor6: #cf9653;
$skinColor7: #c5934e;
$skinColor8: #be8049;
$skinColor9: #aa7038;
$skinColor10: #845325;

//blur color
$blue1: #e5eeff;
$blue2: #b9d2ff;
$blue3: #8bb4ff;
$blue4: #5d96ff;
$blue5: #2e78ff;
$blue6: #005aff;
$blue7: #0049d1;
$blue8: #0039a2;
$blue9: #002974;
$blue10: #001846;
$blue25: #2a647a;
$blue26: #ddf1f8;
