$primaryColor: #02475b;
$secondaryColor: #fc9916;
$trunkWhite: #d0e9ff;
$tertiaryColor: #00b38e;
$black: #000000;
$black10: #1b1b1b;
$dark-black: #0003;
$white: #ffffff;
$fadedWhite: #f4f4f4;
$offWhite: #f7f7f7;
$lightWhite: #e7e7e5;
$mintCream: #e5efe5;
$whiteSmoke: #f9f9f9;
$aquaBlue: #0087ba;
$darkGrey: #080808;
$lightGrey: #f7f8f5;
$ashGrey: #e0e0e0;
$grey: #808080;
$grey11: #c5c5c5;
$fadedAsh: #666;
$dune: #4b4a48;
$blue: #106c89;
$robinEggBlue: #bdedff;
$darkRed: #890000;
$smaltBlue: #4e808d;
$red: #e50000;
$lightRed: #c5411e;
$green: #4caf50;
$raisinBlack: #212223;
$bodybgColor: #fff;
$darkGreen: #228a87;
$lightBlack: #121414;
$borderGray: #71716e;
$textGray: #666666;
$labelGray: #989795;
$whatsappGreen: #61c857;
$darkBlue: #020d11;
$creamTruffle: #ffedc2;
$orange10: #fcb716;
$orange11: #fecf62;
$orange12: #fca317;
$orange13: #fc6619;
$orange14: #f0533b;
$primary2: #01475b;
$blue11: #01667c;
$blue12: #007c9d;
$blue13: #007c93;
$blue14: #00b0c1;
$blue15: #1c4659;
$blue16: #2c4c70;
$blue17: #0092c2;
$blue18: #00889e;
$blue19: #00a7b9;
$blue20: #0589bb;
$blue21: #3d88a0;
$blue22: #366d7c;
$blue23: #0563c1;
$blue24: #1d1b95;
$dark-blue: #2b2d30;
$offWhite2: #fafbf9;
$offWhite4: #f0f1ec;
$lightWhite2: #edf0f0;
$lightWhite3: #d8d8d8;
$grey11: #979797;
$grey12: #eeeeee;
$grey13: #cccccc;
$grey14: #c4c4c4;
$grey15: #ddd;
$grey16: #888;
$smaltBlue2: #658f9b;
$red11: #ff748e;
$lightBlue: #c6eafe;
$green13: #5eacb0;
$green14: #07ae8b;
$emperor: #525252;
$mercury: #e5e5e5;
$cardinal: #c3202b;
$seashell: #f1f1f1;
$gothic: #67919d;
$spanishYellow: #fcb716;
$whisper: #ededed;
$dimGray: #706f6f;
$rajah: #faa95b;
$turqoiseBlue: #7ae1fa;
$peachOrange: #fac28e;
$easternBlue: #007d9c;
$elPasco: #3c3c3b;
$gloomyGrey: #848383;
$houndGrey: #575756;
$chocolateBrown: #663000;
$carrotOrange: #f79520;
$pumpkinOrange: #ec8820;
$mountainMeadow: #31cd95;
$cinnabar: #e94825;
//Font new
$fontNew: "Inter", sans-serif;
$fontIbmSans: IBM Plex Sans, san-serif;

//  Font Variables
$font: "ibm_plex_sansregular"; // 400
$fontMedium: "ibm_plex_sansmedium"; // 500
$fontSemiBold: "ibm_plex_sanssemibold"; // 600
$fontBold: "ibm_plex_sansbold"; // 700
$fontSemiBold2: ibm_plex_sanssemibold;
$fontMedium2: ibm_plex_sansmedium;
// Media Variable
$md: 1024px;
$sm: 768px;
$xs: 600px;
