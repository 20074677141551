@import "./_variables.scss";

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin animation($property) {
  -webkit-animation: $property;
  -ms-animation: $property;
  animation: $property;
}

@mixin flex($align, $justify, $direction) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin text-align($align) {
  text-align: $align;
}

@mixin text-transform($transform) {
  text-transform: $transform;
}

@mixin text-overflow($content) {
  text-overflow: $content;
  overflow: hidden;
  white-space: nowrap;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin font-weight($weight) {
  font-weight: normal;

  @if $weight == 500 {
    font-family: $fontMedium;
  } @else if $weight == 600 {
    font-family: $fontSemiBold;
  } @else if $weight == 700 {
    font-family: $fontBold;
  } @else {
    font-family: $font;
  }
}

@mixin for-size($size) {
  @if $size == phone {
    @media (max-width: 767.9px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 991.9px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 768px) {
      @content;
    }
  }
}

@mixin container {
  width: 100%;
  max-width: 1276px;
  margin: 90px auto 0;
  padding: 0 16px;
  background: #fff;
}

@mixin imageResponsive {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
