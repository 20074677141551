@import "styles/style.module.scss";
.headerCta {
  @include flex(center, flex-start, row);
  gap: 16px;
  button {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 10px 16px;
    border: 1px solid $brandColor10;
    color: $brandColor7;
    border-radius: 8px;
    cursor: pointer;
    background: $white;
    width: 200px;
    height: 40px;
    text-transform: uppercase;
    &.headerCtaBtnFilled {
      color: $white;
      background: $brandColor7;
    }
    &:focus-visible {
      border: unset;
      outline: none;
    }
  }
}
