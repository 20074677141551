@import url("https://www.apollo247.com/config/fonts-var.module.css");
@import "./style.module.scss";

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-size: 14px;
  font-family: $fontNew;
  font-weight: normal;
  background: $bodybgColor;
  color: $primaryColor;
  height: 100%;
  width: 100%;
  &.askApolloView {
    padding: 0;
    @media screen and (max-width: $xs) {
      background: $white;
    }
  }
}

.overflowHidden {
  overflow: hidden;
}

.linearProgress {
  div {
    background: $spanishYellow;
  }
}

a {
  text-decoration: none;
  color: inherit;
  outline: 0;
  /* display: inline-block; */
}

ul,
ol {
  margin: 0;
  padding: 0;
  /* list-style: none; */
}
p {
  /* margin: 0; */
  font-weight: normal;
}
#__next {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-weight: normal; */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}
jdiv[class*="label"][class*="bottom"] {
  bottom: 38px !important;
  height: 62px;
  min-width: unset !important;
  width: 174px;
  right: 90px !important;
  border-radius: 0 !important;
  opacity: 0 !important;
}
jdiv[class*="label"][class*="bottom"]
  > [class*="text"][class*="contentTransitionWrap"] {
  font-size: 0 !important;
}
@media screen and (max-width: 767px) {
  jdiv[class*="orientationRight"][class*="wrap"] {
    bottom: 120px !important;
    right: 72px !important;
    width: 105px !important;
    height: 46px !important;
    overflow: hidden !important;
  }
  jdiv[class*="orientationRight"][class*="wrap"] > [class*="button"] {
    margin: 0 !important;
    width: 105px;
    opacity: 0 !important;
  }
}
blockquote {
  background: linear-gradient(90deg, #fffcf4 0%, #fffdf8 46.35%, #fffffe 100%);
  position: relative;
  padding: 15px 4px 15px 18px;
  margin: 15px 0;
}
blockquote::before {
  content: "";
  width: 29px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border: solid #fcb716;
  border-width: 2px 0 2px 2px;
}
blockquote h1,
blockquote h2,
blockquote h3,
blockquote h4,
blockquote h5,
blockquote h6 {
  font-family: "ibm_plex_sanssemibold";
  font-size: 18px;
  line-height: 23px;
  color: #fcb716;
  margin-bottom: 10px;
}
blockquote p {
  font-size: 14px;
  line-height: 24px;
  color: #474a59;
  margin-bottom: 10px;
}
blockquote p:last-child {
  margin-bottom: 0;
}
.no-scroll {
  overflow: hidden;
}
