@import "styles/style.module.scss";

.navigation {
  max-width: 1024px;
  margin: auto;
  width: 100%;
  li {
    list-style-type: none;
  }
  @media only screen and (max-width: 767.9px) {
    max-width: unset;
    width: 100%;
  }
  .HeaderNavigation {
    @media only screen and (max-width: 767.9px) {
      display: none;
    }
  }
  .bottomNavigation {
    left: 0px;
    right: 0px;
  }
}
