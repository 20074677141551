@import "../../../styles/style.module.scss";

.profileContainer {
  @extend .flexCenter;
  .userAccount {
    width: 48px;
    height: 48px;
    @include border-radius(50%);
    cursor: pointer;
    background: $primaryColor;
    @extend .flexCenter;
  }
}

.accountRoot {
  position: relative;
}
.modalBackground {
  position: fixed;
  background: rgba($black, 0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.tag {
  margin-left: 8px;
}

.userAccountDetails {
  background: $white;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  overflow: hidden;
  z-index: 10;
  @include box-shadow(0 2px 10px 0 rgb($black, 0.1));
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include transition(all 0.4s ease-in-out);
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: #e3e2e2;
  }
  &.userListActive {
    width: 380px;
    @include for-size(phone) {
      width: 300px;
    }
  }
  .userDetails {
    padding: 10px 16px;
    text-align: left;
    position: relative;
    @include for-size(desktop) {
      position: sticky;
      top: 0;
      background: $white;
    }
    .profileNameSection {
      @include flex(center, space-between, row);
      position: relative;
      padding: 10px 0;
      @include for-size(desktop) {
        padding: 20px 0 10px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        color: $black;
      }
      .closeIcon {
        height: 24px;
        width: 24px;
        cursor: pointer;
        color: $black;
      }
    }
    h2 {
      font-size: 23px;
      font-family: $fontBold;
      margin: 0;
    }
    p {
      font-size: 14px;
      font-family: $fontMedium;
      padding: 2px 0;
      line-height: 20px;
      text-transform: uppercase;
    }
    .subTxt {
      font-weight: 400;
      &.uhd {
        text-transform: uppercase;
      }
      @include for-size(desktop) {
        font-size: 14px;
      }
      @include for-size(phone) {
        font-size: 12px;
      }
    }
  }
}

.userAccountList {
  padding: 0 16px;
  margin: 0;
  list-style: none;
  text-align: left;
  > li {
    border-bottom: 1px solid rgba(2, 71, 91, 0.3);
    min-height: 48px;
    color: $grey10;
    a,
    .signOut {
      padding: 8px 0;
      font-size: 14px;
      font-weight: 400;
      @include flex(center, space-between, row);
      min-height: 48px;
      user-select: none;
      cursor: pointer;
      color: $grey10;
      span {
        display: flex;
        align-items: center;
      }
    }
    .signOut {
      justify-content: unset;
      img {
        margin: 0 15px 0 0;
      }
    }

    .leftBx {
      @include flex(center, flex-start, row);
      .icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        @include flex(center, center, row);
        flex-shrink: 0;
        > img {
          @include imageResponsive;
        }
      }
      img {
        padding: 2px;
      }
    }
    &.logOut {
      @include flex(center, space-between, row);
      cursor: pointer;
      .leftBx {
        cursor: pointer;
      }
    }
  }
}

.downloadOptions {
  @include flex(center, space-between, row);
  padding: 20px 16px;
  margin-top: auto;
  .item {
    height: 35px;
    width: calc(50% - 10px);
    @include border-radius(6px);
    @include flex(center, center, row);
    > span {
      font-size: 42px;
      @include for-size(phone) {
        font-size: 36px;
      }
    }
  }
}

.userAccountDetailsNew {
  @include for-size(phone) {
    top: 121px;
    z-index: 10;
    position: fixed;
  }
}
.userAccountList {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  li {
    border-bottom: 1px solid rgba(2, 71, 91, 0.3);
    a {
      padding: 8px 20px;
      font-family: $font;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        align-items: center;
        img,
        span[class*="icon-ic"] {
          margin: 0 10px 0 0;
        }
      }
    }
    img {
      width: 24px;
      max-height: 24px;
    }
  }
  @media only screen and (max-width: 600px) {
    li {
      a {
        padding: 6px 10px !important ;
        font-size: 12px;
      }
    }
  }
}
.downloadAppBtn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 12px;
  font-family: $fontBold;
  padding: 8px 20px;
  display: block;
  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
}
.userListActive {
  width: 300px;
  @media only screen and (max-width: 600px) {
    width: 240px;
  }
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(2, 71, 91, 0.3);
  border-bottom: 1px solid rgba(2, 71, 91, 0.3);
}

.userInfoMissing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(2, 71, 91, 0.3);
}

.downloadOptions {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
}
.popOver {
  > div:first-child {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.loginForm {
  width: 340px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.3);
  background: $white;
  min-height: 240px;
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
}
.topPopover {
  overflow: initial;
  background: none;
  box-shadow: none;
}
